import { createStyles, Title, Text, rem, Container, Group, Button } from "@mantine/core"
import React from "react"
import { useMediaQuery, useViewportSize } from "@mantine/hooks"
import { Link } from "react-scroll"
import { primaryColor, secondaryColor, startDate } from "../../constants"

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "100vh",
    backgroundImage: "url(assets/hero-bg2.jpg)",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundSize: "cover",
    overflow: "hidden",
    position: "relative",
    transition: "all o.3s ease-in-out",

    "&::before": {
      content: "''",
      background: theme.fn.rgba("#000", 0.8),
      position: "absolute",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
    },
  },

  container: {
    maxWidth: 1320,
    color: theme.white,
    position: "absolute",
    bottom: 0,
    left: 0,
    top: "90px",
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },

  majorTitle: {
    fontSize: rem(74),
    letterSpacing: 7,
    textTransform: "uppercase",
    fontWeight: 900,
    textAlign: "center",

    [theme.fn.smallerThan("md")]: {
      fontSize: rem(56),
    },

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(44),
    },

    [theme.fn.smallerThan(475)]: {
      letterSpacing: 2,
      fontSize: rem(28),
    },
  },

  minorTitle: {
    fontSize: rem(44),
    textAlign: "center",
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl,

    [theme.fn.smallerThan("md")]: {
      fontSize: rem(42),
    },

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(32),
      marginTop: theme.spacing.md,
      marginBottom: theme.spacing.md,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(20),
    },
  },

  subTitle: {
    textAlign: "center",
    fontSize: theme.fontSizes.xl,

    [theme.fn.smallerThan("sm")]: {
      fontSize: theme.fontSizes.lg,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: theme.fontSizes.md,
    },
  },
}))

function getSpace(height: number): number {
  if (height <= 480) {
    return 24
  }

  if (height <= 667) {
    return 32
  }

  return 64
}

export function Hero(): React.JSX.Element {
  const { classes, theme } = useStyles()
  const isMd = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const { height } = useViewportSize()

  return (
    <section id="main" className={classes.wrapper}>
      <Container px="md" className={classes.container}>
        <Title order={1} className={classes.majorTitle}>
          <Text
            variant="gradient"
            gradient={{ from: `${primaryColor}.9`, to: `${secondaryColor}.8`, deg: 90 }}
            component="span"
          >Научная</Text><br/> нутрициология<br/>
          <Text
            variant="gradient"
            gradient={{ from: `${primaryColor}.9`, to: `${secondaryColor}.8`, deg: 90 }}
            component="span"
            fw={900}
          >
            2024
          </Text></Title>
        <Title order={2} className={classes.minorTitle}>
          Онлайн-конференция
        </Title>
        <Text className={classes.subTitle}>Проливаем свет науки на тени невежества в сфере питания и медицины</Text>

        <Group mt={getSpace(height)} position="center">
          <Button
            offset={-70}
            to={"schedule"}
            smooth={true}
            duration={1000}
            component={Link}
            size={isMd ? "sm" : "lg"}
            radius="lg"
            variant="light"
            color={`${primaryColor}.9`}
          >
            {startDate}
          </Button>
          <Button
            size={isMd ? "sm" : "lg"}
            radius="lg"
            offset={-70}
            component={Link}
            to={"prices"}
            href={`#prices`}
            smooth={true}
            duration={1000}
            variant="gradient"
            gradient={{ from: `${primaryColor}.9`, to: `${secondaryColor}.8`, deg: 90 }}
          >Участвовать бесплатно</Button>
        </Group>
      </Container>
    </section>
  )
}
