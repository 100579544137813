export const menuItems = [
  { href: "main", label: "Главная" },
  { href: "schedule", label: "Расписание" },
  { href: "speakers", label: "Спикеры" },
  { href: "askQuestion", label: "Задать вопрос" },
]

export const room1 = [
  {
    time: "12:00 - 13:00",
    speakerName: "Татьяна Голубева",
    description: "Кандидат биологических наук, старший научный сотрудник",
    value: "golubeva",
    title: "Конфликт интересов в исследованиях. Кому это выгодно?",
    small: "assets/speakers/golubeva/golubeva-small.jpg",
    cover: "assets/speakers/golubeva/golubeva.jpg",
    texts: [
      "2007 г. - 2012 г. - Новосибирский государственный университет, факультет естественных наук, биологическое отделение, специалист",
      "2017 г. - защита диссертации на соискание ученой степени кандидата биологических наук (клеточная биология, цитология, гистология)",
      "Автор более 50 научных публикаций, в том числе в зарубежных рецензируемых журналах, индекс Хирша 16",
      "Руководитель грантов Российского научного фонда и грантов Президента РФ",
    ],
    social: {
      telegram: "https://t.me/zhazhachka_biologist",
      instagram: "https://www.instagram.com/bio.golubeva/",
      vk: "https://vk.com/lady_zhazha",
      youtube: "https://www.youtube.com/@True_ChemBioFun",
      researchGate: "https://www.researchgate.net/profile/Tatiana-Golubeva-2/",
    },
  },
  {
    time: "13:00 - 14:00",
    speakerName: "Александра Франц",
    description: "Химик, популяризатор науки",
    value: "franz",
    title: "Опасна ли посуда, в которой мы готовим?",
    small: "assets/speakers/franz/franz-small.jpg",
    cover: "assets/speakers/franz/franz.jpg",
    texts: [
      "Высшее образование учителя химии и биологии",
      "Химик, популяризатор науки",
      "Спикер на радио и телевидении",
    ],
    social: {
      telegram: "https://t.me/chemistry_frants",
      instagram: "https://www.instagram.com/frants_alexandra?igsh=MWJkdm15azVwOTFoMA%3D%3D&utm_source=qr",
      youtube: "https://youtube.com/@frants_chemistry?si=-6RPnmdn-jHSjLYk",
      researchGate: "https://www.researchgate.net/profile/Tatiana-Golubeva-2/",
    },
  },
  {
    time: "14:00 - 15:00",
    speakerName: "Валерия Федоровских",
    description: "Врач-педиатр",
    value: "fedorovskih",
    title: "Проблема удержания результата после похудения",
    small: "assets/speakers/fedorovskih/fedorovskih-small.jpg",
    cover: "assets/speakers/fedorovskih/fedorovskih.jpg",
    texts: [
      "Закончила педиатрический факультет ТюмГМУ с отличием",
      "Закончила ординатуру по специальности \"Педиатрия\"",
      "Преподаватель биологической химии студентам медицинских ВУЗов",
    ],
    social: {
      telegram: "https://t.me/valerunaa",
      instagram: "https://www.instagram.com/valeruna_?igsh=aHFwcmZnbDExODhs",
    },
  },
  {
    time: "15:00 - 16:00",
    speakerName: "Дарья Егорова",
    description: "Нутрициолог, предприниматель",
    value: "daria-egorova",
    title: "Как выстроить эффективную коммуникацию с клиентом?",
    small: "assets/speakers/daria-egorova/daria-egorova-small.jpg",
    cover: "assets/speakers/daria-egorova/daria-egorova.jpg",
    texts: [
      "Коуч международного уровня, 4 года в сфере нутрициологии",
      "Образование в нутрициологии: Bodycoach, FPA. Коучинг - 5Prism",
      "Подкаст \"Адекват здоровья\"",
      "Разрабатывает IT-проект в сфере ЗОЖ",
    ],
    social: {
      telegram: "https://t.me/daregorovahealth",
      instagram: "https://www.instagram.com/dar.egorova.health",
    },
  },
  {
    time: "16:00 - 17:00",
    speakerName: "Ольга Косникова",
    description: "Пищевой инженер-технолог субтропических производст, популяризатор науки",
    value: "kosnikova",
    title: "Еда будущего. Что нас ждет?",
    small: "assets/speakers/kosnikova/kosnikova-small.jpg",
    cover: "assets/speakers/kosnikova/kosnikova.jpg",
    texts: [
      "Блогер, писатель, Автор книги \"Страшная химия: Еда с Е-шками\"",
      "Окончила главный пищевой вуз страны МГУПП по специальности \"Инженер-технолог субтропических производств\"",
      "Консультант ТВ-каналов (НТВ, Москва 24, ТВЦ). Вела авторскую передачу на ТВ \"Еда\"",
      "Участник форума \"Учёные против мифов-14\"",
      "11 лет работы в пищевой индустрии",
    ],
    social: {
      telegram: "https://t.me/chemistrybyolga",
      instagram: "https://www.instagram.com/chemistry_by_olga",
      vk: "https://www.www.vk.com/chemistry_by_olga",
      youtube: "https://youtube.com/@chemistry_by_olga",
    },
  },
]

export const room2 = [
  {
    time: "12:00 - 13:00",
    speakerName: "Софья Бакаева",
    description: "Врач-гастроэнтеролог, гепатолог, терапевт",
    value: "bakaeva",
    title: "Бады VS Печень",
    small: "assets/speakers/bakaeva/bakaeva-small.jpg",
    cover: "assets/speakers/bakaeva/bakaeva.jpg",
    texts: [
      "Окончила СЗГМУ им. И.И. Мечникова, диплом с отличием",
      "Множество курсов повышения квалификации, в том числе, по диетологии",
    ],
    social: {
      telegram: "https://t.me/bakaevadoc",
      instagram: "https://www.instagram.com/doctor.bakaeva",
    },
  },
  {
    time: "13:00 - 14:00",
    speakerName: "Амина Мирсакиева",
    description: "Физик, химик, научный популяризатор",
    value: "mirsakieva",
    title: "Микропластик убивает? Как дальше жить?",
    small: "assets/speakers/mirsakieva/mirsakieva-small.jpg",
    cover: "assets/speakers/mirsakieva/mirsakieva.jpg",
    texts: [
      "Магистр и бакалавр химического инжиниринга (КБТУ, Казахстан)",
      "PhD Material Physics (КТН, Швеция, 2017)",
      "100 новых лиц Казахстана 2021",
      "Основательница сообщества женщин-ученых из Казахстана",
    ],
    social: {
      telegram: "https://t.me/belkastrelkapodcast",
      instagram: "https://www.instagram.com/dr_amina_mir",
      custom: "https://belkastrelkapodcast.mave.digital",
    },
  },
  {
    time: "14:00 - 15:00",
    speakerName: "Александр Демченко",
    description: "Персональный тренер, нутрициолог",
    value: "demchenko",
    title: "Мифы о питании, о которых давно нужно забыть",
    small: "assets/speakers/demchenko/demchenko-small.jpg",
    cover: "assets/speakers/demchenko/demchenko.jpg",
    texts: [
      "Окончил Ассоциацию профессионалов фитнеса",
      "Окончил BodyCoach (нутрициология)",
      "Окончил BodyCoach (нутрициология)",
      "Доказательный нутрициолог",
      "Сертифицированый персональный тренер",
    ],
    social: {
      telegram: "https://t.me/hackdiet",
      instagram: "https://www.instagram.com/aleksandrdemchencko?igsh=MmJncm9neGtiNHdx",
      tiktok: "https://www.tiktok.com/@aleksandr.demchenko?_t=8q8BL21HFqh&_r=1",
      custom: "https://aleksandrdemchencko.ru/",
    },
  },
  {
    time: "15:00 - 16:00",
    speakerName: "Анна Берекетова",
    description: "Нутрициолог",
    value: "bereketova",
    title: "Как стремление к ЗОЖ может стать опасным?",
    small: "assets/speakers/bereketova/bereketova-small.jpg",
    cover: "assets/speakers/bereketova/bereketova.jpg",
    texts: [
      "Специалист по питанию и пищевому поведению",
      "Преподаватель и методист учебного центра Body Coach",
      "Ассистент врача-диетолога Александра Бурлакова",
    ],
  },
  {
    time: "16:00 - 17:00",
    speakerName: "Татьяна Хитрова",
    description: "Патологоанатом",
    value: "khitrova",
    title: "Как на вскрытии выглядят внутренние органы при ожирении?",
    small: "assets/speakers/khitrova/khitrova-small.jpg",
    cover: "assets/speakers/khitrova/khitrova.jpg",
    texts: [
      "Спикерша Антропогенеза и научных форумов",
      "Преподавательница анатомии",
      "Автор популярного блока \"Patologinya\"",
    ],
    social: {
      instagram: "https://www.instagram.com/patologinya?igsh=aXBneXRqdzY4MXRt",
      youtube: "https://www.youtube.com/@patologinya",
      custom: "https://khitrovamed.com/",
    },
  },
]

export const schedule = [
  {
    time: "11:00 - 12:00",
    speakerName: "Тамаз Гаглошвили",
    description: "Терапевт, кардиолог, геронтолог, организатор здравоохранения",
    value: "gagloshvili",
    title: "Диета или статины, с чего начать?",
    small: "assets/speakers/gagloshvili/gagloshvili-small.jpg",
    cover: "assets/speakers/gagloshvili/gagloshvili.jpg",
    texts: [
      "Автор книги \"Слушай сердце. Кардиолог о мифах про самые распространенные заболевания\"",
      "Автор блога - blog_kardiologa (https://www.instagram.com/blog_kardiologa)",
    ],
  },
  {
    time: "11:00 - 12:00",
    speakerName: "Евгения Меглинская",
    description: "Руководитель Школы Консультантов по психологической коррекции пищевого поведения",
    value: "meglinskaya",
    title: "Интуитивное или осознанное питание?",
    small: "assets/speakers/meglinskaya/meglinskaya-small.jpg",
    cover: "assets/speakers/meglinskaya/meglinskaya.jpg",
    texts: [
      "Магистр Манчестерского Университета (University of Manchester), Великобритания",
      "Автор книг \"Стоп срывам и перееданиям\" и \"Здоровый похудизм\"",
      "Приглашенный эксперт для телеканалов \"Мама-ТВ\", \"Домашний\", \"Здоровое ТВ\"",
    ],
  },
  {
    time: "12:00 - 13:00",
    speakerName: "Денис Лебедев",
    description: "Врач-эндокринолог, специалист по клиническим исследованиям",
    value: "lebedev",
    title: "Гормоны и масса тела",
    small: "assets/speakers/lebedev/lebedev-small.jpg",
    cover: "assets/speakers/lebedev/lebedev.jpg",
    texts: [
      "Выпускник медицинского факультета СПБГУ, ординатуры и аспирантуры по эндокринологии Национального исследовательского центра им. В.А.Алмазова",
      "Автор более 10 полнотекстовых статей, в том числе, в рейтинговых зарубежных журналах",
    ],
  },
  {
    time: "12:00 - 13:00",
    speakerName: "Анастасия Левитская",
    description: "Врач-гастроэнтеролог",
    value: "levitskaya",
    title: "В погоне за желчью",
    small: "assets/speakers/levitskaya/levitskaya-small.jpg",
    cover: "assets/speakers/levitskaya/levitskaya.jpg",
    texts: [
      "Московский областной научно-исследовательский клинический институт им. М. Ф. Владимирского (ГБУЗ МО МОНИКИ им. М. Ф. Владимирского)",
      "Аспирантура по специальности Гастроэнтерология",
      "Участие (в том числе выступления) в профессиональных медицинских конференциях, написание статей, публикации ECCO, EUG и др.",
      "Работа с ординаторами и врачами первичного звена на кафедре гастроэнтерологии ФУВ МОНИКИ",
      "Работа в качестве эксперта курса DOCMASCHOOL \"Гастроэнтерология, которая работает\", \"Терапия\"",
    ],
  },
  {
    time: "13:00 - 14:00",
    speakerName: "Джесси Хмеляр",
    description: "Наставник врачей в соц. сетях, врач-гастроэнтеролог",
    title: "Личный бред мракобеса: Как стать узнаваемым нутрициологом с научным подходом?",
    value: "khmelyar",
    small: "assets/speakers/khmelyar/khmelyar-small.jpg",
    cover: "assets/speakers/khmelyar/khmelyar.jpg",
    texts: [
      "Спикер курсов DocMaSchool",
      "Сертификат Good clinical practice (GCP)",
      "2016 - \"Лечебное дело\", Балтийский федеральный университет имени Иммануила Канта",
      "2017 - Интернатура по специальности \"Терапия\", Балтийский федеральный университет имени Иммануила Канта",
      "2018 - Профессиональная переподготовка \"Общая врачебная практика (семейная медицина)\", Северо-Западный государственный медицинский университет им. И. И. Мечникова",
      "2018 - Профессиональная переподготовка \"Гастроэнтерология\", Северо-Западный государственный медицинский университет им. И. И. Мечникова",
    ],
  },
  {
    time: "13:00 - 14:00",
    speakerName: "Анна Коновалова",
    description: "Врач-эндокринолог, диетолог",
    value: "konovalova",
    title: "Можно ли всех похудеть?",
    small: "assets/speakers/konovalova/konovalova-small.jpg",
    cover: "assets/speakers/konovalova/konovalova.jpg",
    texts: [
      "Врач-эндокринолог, диетолог",
      "Член Российской ассоциации эндокринологов",
      "Член Национальной ассоциации клинического питания",
      "Член Российского союза нутрициологов, диетологов и специалистов пищевой индустрии",
      "Член Ассоциации гинекологов-эндокринологов России",
      "Член Российской ассоциации по остеопорозу",
    ],
  },
  {
    time: "14:00 - 15:00",
    speakerName: "Жанна Валова",
    description: "Педиатр, консультант по ГВ, эксперт в теме питания детей и коррекции пищевых отношений",
    value: "valova",
    title: "Расстройства питания у детей",
    small: "assets/speakers/valova/valova-small.jpg",
    cover: "assets/speakers/valova/valova.jpg",
    texts: [
      "Автор обучающего марафона \"Мы в одной ложке\" для родителей",
      "Эксперт в теме питания детей и коррекции пищевых отношений",
    ],
  },
  {
    time: "14:00 - 15:00",
    speakerName: "Татьяна Денисова",
    description: "Врач-эндокринолог, диетолог, клинический психолог",
    value: "denisova",
    title: "Переедания - это всегда РПП?",
    small: "assets/speakers/denisova/denisova-small.jpg",
    cover: "assets/speakers/denisova/denisova.png",
    texts: [
      "Окончила Первый Московский государственный Медицинский университет им. И. М. Сеченова  по специальности лечебное дело",
      "Проходила обучение в клинической ординатуре по специальности Эндокринология в Эндокринологическом Научном центре",
      "Профессиональная переподготовка по диетологии (РУДН) и клинической психологии( Институт Организационной психологии)",
      "Специализация на диетологическом консультировании пациентов с метаболическими нарушениями, избыточной массой тела, ожирением,  РПП, девушек/женщин с ФГА на фоне спорта, диет и стресса",
      "Спикер образовательной онлайн-платформы Meducation. Обучающие лекции,  вебинары для врачей, пациентов,  посвящённые эндокринным нарушениям, а также питанию в норме и  при различных патологиях",
      "Стаж работы более 13 лет",
    ],
  },
  {
    time: "16:00 - 17:00",
    speakerName: "Александр Бурлаков",
    description: "Врач-диетолог доказательной медицины",
    value: "burlakov",
    title: "Фрукты и овощи уже не те",
    small: "assets/speakers/burlakov/burlakov-small.jpg",
    cover: "assets/speakers/burlakov/burlakov.jpg",
    texts: [
      "Врач-диетолог доказательной медицины. Тренер - преподаватель. Научный журналист",
      "Специалист по диетологическому сопровождению спортсменов UFC, ACA, RCC",
      "Автор книги: \"В гармонии с едой\"",
    ],
  },
  {
    time: "16:00 - 17:00",
    speakerName: "Алексей Варфоломеев",
    description: "Специалист по модификации образа жизни",
    value: "varfolomeev",
    title: "Расстрельный список рекомендаций по питанию",
    small: "assets/speakers/varfolomeev/varfolomeev-small.jpg",
    cover: "assets/speakers/varfolomeev/varfolomeev.jpg",
    texts: [
      "Специалист по модификации образа жизни",
      "Нутрициолог",
      "Судья международного класса по вейкборду IWWF/CWWC",
    ],
  },
]
