import React from "react"
import { Button, Text, Paper, createStyles, Stack, Group, Center, Divider } from "@mantine/core"
import { IconCheck, IconFreeRights, IconX } from "@tabler/icons-react"

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: "100%",
    maxWidth: 375,
    position: "relative",
    background: "transparent",
  },

  innerBox: {
    overflow: "hidden",
    borderRadius: theme.radius.xl,
    background: theme.white,
    position: "relative",
    margin: "0 auto",
    paddingBottom: 30,
    zIndex: 0,
    border: `2px solid ${theme.colors.gray[3]}`,
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 1px 5px",

    "&::before": {
      content: "\"\"",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "url(/assets/pattern.png)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      opacity: 0.5,
      zIndex: -1,
      transform: "rotate(-180deg)",
    },
  },

  iconBox: {
    position: "relative",
    padding: "50px 30px 0",
    textAlign: "center",
    filter: "drop-shadow(0px 2px 3px #222)",
  },

  textContainer: {
    paddingTop: 32,
    marginTop: "auto",

    [theme.fn.smallerThan("sm")]: {
      paddingTop: 24,
    },
  },

  textGroup: {
    gap: theme.spacing.md,

    [theme.fn.smallerThan("sm")]: {
      gap: theme.spacing.xs,
    },
  },

  text: {
    fontSize: theme.fontSizes.md,
    fontWeight: 500,

    [theme.fn.smallerThan("sm")]: {
      fontSize: theme.fontSizes.sm,
    },
  },
}))

const onClick = () => {
  // @ts-ignore
  // _tmr.push({ type: "reachGoal", id: 3412322, goal: "Регистрация free" })
  window.open("https://t.me/nauchnutr_conf_bot", "_blank")
}

export function PricingFreeSection() {
  const { classes } = useStyles()

  return (
    <Paper className={classes.wrapper}>
      <div className={classes.innerBox}>
        <div className={classes.iconBox}>
          <IconFreeRights size={70} stroke={1.5}/>
        </div>

        <Text fw={700} align="center" size={22} mb={32}>
          Базовый
        </Text>

        <Text align="center" size={32} fw={900}>
          Бесплатно
        </Text>

        <Center className={classes.textContainer}>
          <Stack spacing={0}>
            <Group className={classes.textGroup}>
              <IconCheck color="green"/>
              <Text className={classes.text}>Онлайн участие в конференции</Text>
            </Group>
            <Divider my="sm" variant="dashed"/>
            <Group className={classes.textGroup}>
              <IconX color="red"/>
              <Text className={classes.text}>Сертификат участника</Text>
            </Group>
            <Divider my="sm" variant="dashed"/>
            <Group className={classes.textGroup}>
              <IconX color="red"/>
              <Text className={classes.text}>Записи всех выступлений</Text>
            </Group>
          </Stack>
        </Center>

        <Group className={classes.textContainer} position="center">
          <Button
            color="dark"
            radius="lg"
            size="md"
            onClick={onClick}
          >
            Зарегистрироваться
          </Button>
        </Group>

      </div>
    </Paper>
  )
}
